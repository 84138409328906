import React, { useEffect } from 'react'
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>À Propos de Pokemark | Boutique de Cartes Pokémon</title>
        <meta
          name="description"
          content="Bienvenue chez Pokémark, votre destination ultime pour les cartes Pokémon rares et recherchées. Découvrez notre histoire, notre passion pour Pokémon, et explorez notre vaste collection. Notre équipe dévouée s'efforce de fournir une expérience exceptionnelle aux collectionneurs du monde entier."
        />
      </Helmet>
      <div className='m-8 md:m-20 text-center lg:text-start'>
        <div className='px-4 bg-gray-900 flex justify-between items-center w-full lg:w-72 h-14 text-white hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 dark:hover:bg-gray-600'>
          <p className="text-xl font-medium leading-5 "> À Propos</p>
        </div>

        <div >
          <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
            État des Cartes Pokémon
          </p>
          <div className='mt-4 lg:w-4/5 xl:w-3/5'>
            <p className='text-base leading-6 text-gray-600'>
              Explorez notre collection de cartes Pokémon d'occasion, soigneusement préservées pour garantir une qualité optimale. Chacune de nos cartes, bien que d'occasion, est inspectée attentivement afin de vous assurer des produits en excellent état. Nous mettons tout en œuvre pour préserver leur éclat d'origine, vous offrant ainsi des cartes dignes de votre passion. Pour toute question sur l'état d'une carte spécifique, n'hésitez pas à nous contacter. Nous sommes là pour vous offrir une expérience d'achat exceptionnelle.          </p>
          </div>
        </div>

        <div >
          <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
            Livraison et Emballage
          </p>
          <div className='mt-4 lg:w-4/5 xl:w-3/5'>
            <p className='leading-6 text-gray-600'>
              Nous nous engageons à vous fournir une expérience de livraison rapide, fiable et sécurisée pour vos précieuses cartes Pokémon. Chaque commande est soigneusement emballée pour assurer la protection optimale de vos cartes tout au long du processus de livraison. Notre équipe dévouée s'assure que chaque colis est manipulé avec le plus grand soin pour garantir que vos cartes arrivent chez vous dans un état impeccable.
            </p>
          </div>
        </div>

        <div >
          <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
            Délai de Livraison
          </p>
          <div className='mt-4 lg:w-4/5 xl:w-3/5'>
            <p className='text-base leading-6 text-gray-600'>
              Nous comprenons l'excitation qui entoure la réception de vos cartes Pokémon. C'est pourquoi nous nous efforçons de traiter et d'expédier chaque commande dans les plus brefs délais. Le délai de livraison dépend de votre emplacement, mais nous nous engageons à vous tenir informé à chaque étape du processus. Vous pouvez consulter les détails spécifiques concernant les délais de livraison estimés lors du processus de commande.
            </p>
          </div>
        </div>

        <div >
          <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
            Frais de Livraison
          </p>
          <div className='mt-4 lg:w-4/5 xl:w-3/5'>
            <p className='text-base leading-6 text-gray-600'>
              Nos frais de livraison sont transparents et équitables. Ils sont calculés en fonction de divers facteurs, notamment la destination de la livraison et le poids total de votre commande. Lors du processus de commande, vous serez informé des frais de livraison applicables. Nous nous efforçons de proposer des tarifs compétitifs tout en assurant la qualité de notre service de livraison.          </p>
          </div>
        </div>


        <div >
          <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
            Nos Obligations
          </p>
          <div className='mt-4 lg:w-4/5 xl:w-3/5'>
            <p className='text-base leading-6 text-gray-600'>
              En tant que vendeur responsable, nous nous engageons à respecter toutes les lois et réglementations en vigueur en matière de vente de cartes Pokémon. Nous garantissons l'authenticité de toutes les cartes que nous vendons et nous nous efforçons de fournir des descriptions précises pour chaque produit. En cas de problème avec votre commande, notre équipe du service client est là pour vous assister.
            </p>
          </div>
        </div>

        <div >
          <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
            Vos Obligations
          </p>
          <div className='mt-4 lg:w-4/5 xl:w-3/5'>
            <p className='text-base leading-6 text-gray-600'>
              Nous encourageons nos clients à lire attentivement les descriptions des produits avant de passer commande. Assurez-vous de vérifier les détails tels que l'édition, l'état de la carte, et d'autres informations pertinentes. En cas de questions ou de préoccupations, n'hésitez pas à contacter notre service client avant de finaliser votre achat.          </p>
          </div>
        </div>
      </div>
      <Footer />
    </>




  )
}

export default About