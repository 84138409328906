import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: "",
    firstName: "",
    lastName: "",
    _id: "",
    adresse: "",
    ville: "",
    pays: "",
    addProduct: false,
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        loginRedux: (state, action) => {
            state._id = action.payload.data._id;
            state.firstName = action.payload.data.firstName;
            state.lastName = action.payload.data.lastName;
            state.email = action.payload.data.email;
            state.adresse = action.payload.data.adresse;
            state.ville = action.payload.data.ville;
            state.pays = action.payload.data.pays;
            state.addProduct = action.payload.data.addProduct;
        },
        logoutRedux: (state, action) => {
            state._id = "";
            state.firstName = "";
            state.lastName = "";
            state.email = "";
            state.adresse = "";
            state.ville = "";
            state.pays = "";
            state.addProduct = false;

        },
        updateUserInfo: (state, action) => {
            Object.assign(state, action.payload);
        },

    }
})

export const { loginRedux, logoutRedux, updateUserInfo } = userSlice.actions;

export default userSlice.reducer;