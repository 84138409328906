import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import CardFeature from "../component/CardFeature"
import { useParams } from 'react-router-dom'
import { FaArrowUp } from "react-icons/fa";
import { setDataProduct, updateLastActivityTime } from '../redux/productSlice';
import { RotatingLines } from 'react-loader-spinner';
import pikachuRupture from "../media/pikachuRupture.webp";
import { Helmet } from 'react-helmet';


const Cartes = () => {
  const dispatch = useDispatch();
  const products = useSelector(state => state.product.productList);
  const { categorie } = useParams();
  const [selectCategorie, setSelectCategorie] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  
  const toggleVisibility = () => {
    setIsVisible(window.scrollY > 100);
  };
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  
  const handleChangeCat = (e) => {
    dispatch(updateLastActivityTime());
    const selectedCat = e.target.value;
    setSelectCategorie(selectedCat);
  };

  const handleSearchChange = (e) => {
    dispatch(updateLastActivityTime());
    const searchTermValue = e.target.value;
    setSearchTerm(searchTermValue);
  };

  useEffect(() => {
    dispatch(setDataProduct(products));
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  useEffect(() => {
    if (categorie) {
      setSelectCategorie(categorie);
    } else {
      setSelectCategorie("all")
    }
  }, [categorie]);

  useEffect(() => {
    setLoading(true);
    const newFilteredProducts = products.filter(
      (product) =>
        (selectCategorie === 'all' || product.categorie === selectCategorie) &&
        (searchTerm === '' ||
          product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.categorie.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    setTimeout(() => {
      setLoading(false);
      setFilteredProducts(newFilteredProducts);
    }, 2000);
  }, [selectCategorie, searchTerm, products]);

  return (

    <div>
      <Helmet>
        <title>
        PokéMark, Explorez toutes les cartes Pokémon disponibles | Boutique en Ligne
        </title>
        <meta
          name="description"
          content="Découvrez la plus vaste collection de cartes Pokémon en ligne. Notre boutique propose une variété impressionnante de cartes, des éditions classiques aux séries les plus récentes. Trouvez et achetez les cartes rares, recherchées et populaires pour enrichir votre collection Pokémon."
        />
      </Helmet>
      <div className='mx-auto my-10 w-screen max-w-screen-md'>
        <div className='flex flex-col'>
          <div className='rounded-xl border border-gray-200 bg-white p-6 shadow-lg'>
            <form className='' >
              <div className='relative mb-10 w-full flex items-center justify-between rounded-md'>
                <svg className="absolute left-2 block h-5 w-5 text-gray-400" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="11" cy="11" r="8" ></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <input type="text" id="search"  onChange={handleSearchChange} name="search" placeholder="Rechercher un pokémon" className='h-12 w-full cursor-text rounded-md border border-gray-100 bg-gray-100 py-4 pr-3 pl-12 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' />
              </div>

              <div className='grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                <div className="flex flex-col">
                  <label htmlFor="categorie" className="text-sm font-medium text-stone-600">Extensions</label>

                  <select id="categorie" onChange={handleChangeCat} value={selectCategorie} className="mt-2 block w-full rounded-md border border-gray-100 bg-gray-100 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
                    <option key="all" value="all">All</option>
                    <option key="Silver Tempest" value="Silver Tempest">Silver Tempest</option>
                    <option key="Vivid Voltage" value="Vivid Voltage">Vivid Voltage</option>
                    <option key="Chilling Reign" value="Chilling Reign">Chilling Reign</option>
                    <option key="Evolving Skies" value="Evolving Skies">Evolving Skies</option>
                    <option key="Fusion Strike" value="Fusion Strike">Fusion Strike</option>
                    <option key="Battle Styles" value="Battle Styles">Battle Styles</option>
                    <option key="Scarlet & Violet" value="Scarlet & Violet">Scarlet & Violet</option>
                    <option key="Black Star Promos" value="Black Star Promos">Black Star Promos</option>
                    <option key="McDonald's Collection 25th Anniversary" value="McDonald's Collection 25th Anniversary">McDonald's Collection 25th Anniversary</option>
                    <option key="McDonald's Match Battle 2023" value="McDonald's Match Battle 2023">McDonald's Match Battle 2023</option>
                    <option key="Coin" value="Coin">Coin</option>
                  </select>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>

      <div className=" mx-auto justify-center flex flex-wrap">

        {
          loading ?
          (
            <div className='mx-auto my-10'>   
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.70"
                width="96"
                visible={true}
              />
            </div>
            ): (
          filteredProducts.length >= 1 ? (
            filteredProducts.map(product => (
              <CardFeature key={product._id} name={product.name} image={product.image} description={product.description} prix={product.prix} categorie={product.categorie} id={product._id} stock={product.stock} />
            ))
          ):(
          <div className='m-auto'>
            <img src={pikachuRupture} loading="lazy" alt="pokemon" className='h-44 w-44 m-auto md:w-96 md:h-96 rounded-full object-cover' />
            <h1 className="py-5 text-center font-medium leading-4 text-gray-800">Nous sommes en rupture de ce pokémon</h1>
          </div>
          ))
        }      
      </div>
      <div>
        {isVisible && (
          <button onClick={scrollToTop} className="fixed bottom-10 right-10 bg-gray-800 text-white px-4 py-2 rounded">
            <FaArrowUp />
          </button>
        )}
      </div>
    </div>
  )
}

export default Cartes