import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productList: [],
    cartItem: [],
    lastActivityTime: null,
}

export const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        setDataProduct: (state, action) => {
            state.productList = [...action.payload]
        },

        addCartItem: (state, action) => {
            const product = action.payload;
            const alreadyInCart = state.cartItem.some((el) => el._id === product._id);
            if (alreadyInCart) {
                const existingItem = state.cartItem.find((item) => item._id === product._id);
                existingItem.quantity += 1;
                const total = existingItem.prix * existingItem.quantity;
                existingItem.total = total.toFixed(2);

            } else {
                const total = product.prix;
                const updateCart = [...state.cartItem, { ...product, quantity: 1, stock: product.stock - 1, total }]
                state.cartItem = updateCart;
                localStorage.setItem('cart', JSON.stringify(updateCart));
            }
        },
        deleteCartItem: (state, action) => {
            const itemId = action.payload;
            const itemIndex = state.cartItem.findIndex((item) => item._id === itemId)
            if (itemIndex !== -1) {
                const deletedItem = state.cartItem[itemIndex];
                deletedItem.stock += 1;
                state.cartItem.splice(itemIndex, 1);

            }
        },
        deleteAllCart: (state) => {
            try {
                const response = fetch("api/updateStock/timeout", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ produits: state.cartItem }),
                });
               

            } catch (error) {
                console.log(error);
            }
            return {
                ...state,
                cartItem: []
            }

        },  updateLastActivityTime: (state, action) => {
            state.lastActivityTime =  new Date().getTime();
          },
    }

})

export const { setDataProduct, addCartItem, deleteCartItem, deleteAllCart, updateLastActivityTime } = productSlice.actions;

export default productSlice.reducer;