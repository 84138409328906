import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import dragon from "../media/dragon.webp";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { logoutRedux, updateUserInfo } from '../redux/userSlice';
import { Helmet } from 'react-helmet';

const Compte = () => {
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [newFirstName, setNewFirstName] = useState(userData.firstName);
  const [newLastName, setNewLastName] = useState(userData.lastName);
  const [newAddress, setNewAddress] = useState(userData.adresse);
  const [newVille, setNewVille] = useState(userData.ville);
  const [newPays, setNewPays] = useState(userData.pays);

  const handleProfileUpdate = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(`api/updateuser/${userData._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: newFirstName,
          lastName: newLastName,
          adresse: newAddress,
          ville: newVille,
          pays: newPays,
        }),
      });

      if (response.ok) {
        const updatedUser = await response.json();
        dispatch(updateUserInfo(updatedUser));
        toast.success("Les informations ont été modifiées avec succès")
      } else {
        toast.error("Il y a eu une erreur lors de l'enregistrement. Réessayez.")

      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil utilisateur :', error);
      toast.error("Il y a eu une erreur lors de l'enregistrement. Réessayez.")

    }
  };

  const handleDelete = () => {
    confirmAlert({
      message: "Êtes-vous sûr de vouloir supprimer votre compte ?",
      buttons: [
        {
          label: "Oui",
          onClick: async () => {
            const userId = userData._id;
            const fetchData = await fetch('api/deleteuser', {
              method: 'DELETE',
              headers: { "content-type": "application/json" },
              body: JSON.stringify({ userId })
            });

            if (fetchData.status === 200) {
              toast.success("Votre compte a bien été supprimé.");
                dispatch(logoutRedux());
                navigate("/");
            }
            else {
              toast.error("Une erreur s'est produite.");
            }
          }

        },
        {
          label: "Non",
          onClick: () => {
            return;
          }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    })
  }


  return (
    userData.email ? (
      <div className="flex flex-wrap w-full">
        <Helmet>
        <title>Votre Compte Pokémark | Boutique de Cartes Pokémon</title>
        <meta
          name="description"
          content="Accédez à votre compte Pokémark pour personnaliser votre profil, suivre vos commandes et gérer votre précieuse collection de cartes Pokémon. Profitez d'une expérience exclusive réservée aux membres de Pokemark."
        />
      </Helmet>

        <div className="flex flex-col w-full mt-10 md:w-1/2">
          <div className='flex flex-col justify-center px-8 pt-8 my-auto md:justify-start md:pt-0 md:px-24 lg:px-32'>
            <p className='text-3xl text-center'>
              Bienvenue
            </p>
            <form onSubmit={handleProfileUpdate} className='flex flex-col pt-3 md:pt-8'>
              <div className='flex flex-col pt-4'>
                <div className='flex relative'>
                  <input placeholder='Prénom' className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" type={'text'} id="firstname" value={newFirstName} name="firstname" onChange={(e) => setNewFirstName(e.target.value)} />
                </div>
              </div>
              <div className='flex flex-col pt-4 mb-12'>
                <div className='flex relative'>
                  <input className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder='Nom' type={'text'} id="lastname" value={newLastName} name="lastname" onChange={(e) => setNewLastName(e.target.value)} />
                </div>
              </div>
              <div className='flex flex-col pt-4 '>
                <div className='flex relative'>
                  <input className=" flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder='Adresse' type={'text'} id="adresse" value={newAddress} name="adresse" onChange={(e) => setNewAddress(e.target.value)} />
                </div>
              </div>

              <div className='flex flex-col pt-4'>
                <div className='flex relative'>
                  <input className=" flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder='Code Postal et Ville' type={"text"} id="ville" name="ville" value={newVille} onChange={(e) => setNewVille(e.target.value)} />
                </div>
              </div>

              <div className='flex flex-col pt-4 mb-12'>
                <div className='flex relative'>
                  <input className=" flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder='Pays' type={"text"} id="pays" name="pays" value={newPays} onChange={(e) => setNewPays(e.target.value)} />
                </div>
              </div>
              <button type="submit" className="w-full px-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-black shadow-md hover:text-black hover:bg-white focus:outline-none focus:ring-2">
                <span className="w-full">
                  Modifier vos informations
                </span>
              </button>
              {
                location.state && location.state.fromCart && (
                  <Link to={"/panier"} className="flex w-full justify-center items-center mx-auto my-3">
                    <button className="mt-6 py-3  hover:bg-gray-900 hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  border border-gray-800 w-full text-base font-medium leading-3 text-gray-800 md:mt-0"> Retour au panier</button>
                  </Link>
                )
              }
            </form>
            <div className="pt-12 pb-12 text-center">
              <p>
                Supprimer votre compte ?
                <br />
                <button className=" text-red-500 font-semibold underline" onClick={handleDelete}>
                  Cliquer-ici
                </button>
              </p>
            </div>
          </div>
        </div>
        <div className="w-1/2 shadow-2xl">
          <img className="hidden object-cover w-full h-screen md:block" alt="pokemon" src={dragon} loading="lazy" />
        </div>
      </div>

    ) : navigate("/signup")
  )
}

export default Compte;