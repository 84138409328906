import React, { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import HomeCard from '../component/HomeCard';
import { addCartItem, updateLastActivityTime } from '../redux/productSlice';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';


const Carte = () => {
    const { id } = useParams();
    const productData = useSelector(state => state.product.productList);
    const productDisplay = productData.filter(el => el._id === id);
    const categorie = productDisplay[0].categorie;
    const dispatch = useDispatch();


    const homeProductCartListCat = productData.filter((el) => el.categorie === categorie, []).slice(-3);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const handleAddCartProduct = async () => {
        dispatch(updateLastActivityTime());
        const productId = id;
        try {
            const response = await fetch(`/api/updateStock/${productId}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ quantity: 1 })
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.error || "Erreur lors de la mise à jour du stock");
            } else {
                const updateProduct = await response.json();
                dispatch(addCartItem(updateProduct.data));
                toast.success('Le produit a été ajouté avec succès.');
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (

        <div className='mt-11 p-1 sm:p-6 relative'>
            <Helmet>
                <title>
                    {productDisplay[0].name} - Carte Pokémon à Vendre | Boutique en Ligne
                </title>
                <meta
                    name="description"
                    content={`Découvrez et achetez la carte Pokémon ${productDisplay[0].name}. Une édition spéciale de notre collection, parfaite pour les collectionneurs passionnés. Consultez les détails de la carte et ajoutez-la à votre collection dès maintenant.`}
                />
            </Helmet>
            <div className='mb-20 relative flex max-w-3xl m-auto bg-white shadow-2xl overflow-hidden'>
                <div className='w-1/2 sm:1/3 '>
                    <img className='w-full h-full object-contain' src={productDisplay[0].image} alt={productDisplay[0].name} />
                </div>
                <div className='w-2/3 p-4 relative'>
                    <h1 className='text-gray-900 font-bold h-14 sm:h-20 text-sm sm:text-lg lg:text-3xl'>{productDisplay[0].name}</h1>
                    <h2 className='text-gray-900 lg:text-2xl h-10 pt-1 text-xs sm:text-lg sm:h-20 font-semibold'>{productDisplay[0].categorie}</h2>
                    <p className='mt-2 h-18 sm:h-28 text-gray-600 pt-1 pb-1 text-sm'>{productDisplay[0].description}</p>
                    <div className='flex item-center justify-between pt-1 h-8'>
                        <h1 className='text-gray-700 font-bold sm:text-xl'>{productDisplay[0].prix} CHF</h1>
                    </div>
                    <button className='hover:scale-105 px-3 py-2 mt-1 bg-gray-800 text-white text-xs font-bold uppercase rounded' onClick={handleAddCartProduct}>Ajouter au panier</button>
                </div>
            </div>

            <Link to={`/cartes/${categorie}`} className='px-4 bg-gray-900 flex justify-between items-center w-full lg:w-80 h-20 text-white hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 dark:hover:bg-gray-600'>
                <p className="text-xl font-medium leading-5">Plus de {categorie} </p>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path d="M6.66663 16H25.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20 21.3333L25.3333 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20 10.6667L25.3333 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </Link>
            <div className='flex flex-wrap mt-10 '>
                {
                    homeProductCartListCat.map(el => {
                        return (
                            <HomeCard
                                key={el._id}
                                name={el.name}
                                image={el.image}
                                prix={el.prix}
                                categorie={el.categorie}
                                description={el.description}
                                id={el._id}
                                stock={el.stock}
                            />
                        )
                    })
                }
            </div>





        </div>
    )
}

export default Carte