import React, { useState } from 'react';
import { FiUpload } from "react-icons/fi";
import { ImagetoBase64 } from '../utility/ImagetoBase64';
import toast from "react-hot-toast";
import { useSelector } from 'react-redux';



const Addproduct = () => {
  const userData = useSelector((state) => state.user);
  const [outOfStockProducts, setOutOfStockProducts] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
    categorie: "",
    image: "",
    prix: "",
    stock: "",
  });

  const handleButtonClick = async () => {
    try {
      const response = await fetch('api/rupture-stock', {
        method: "GET"
      });
      const data = await response.json();
      setOutOfStockProducts(data);
    } catch (error) {
      console.log(error)
    }
  };


  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const uploadImage = async (e) => {
    const data = await ImagetoBase64(e.target.files[0]);

    setData((prev) => {
      return {
        ...prev,
        image: data
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await fetch('api/uploadProduct', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          data
        ),
      });

      toast.success("Le produit a bien été enregistrer");
      setData(() => {
        return {
          name: "",
          categorie: "",
          image: "",
          prix: "",
          description: "",
          stock: "",
        }
      })

    } catch (error) {
      toast.error("Il y a eu une erreur durant l'enregistrement.")
    }
  }



  return (

    userData.addProduct ? (

      <div className='p-4 '>
        <form className='m-auto w-full max-w-md shadow flex flex-col p-3 bg-white' onSubmit={handleSubmit} >
          <label htmlFor='name' className='p-1'>Nom</label>
          <input type={"text"} name="name" className='bg-slate-100' onChange={handleOnChange} value={data.name} />

          <label htmlFor='description' className='p-1'>Description</label>
          <textarea value={data.description} rows={4} type={"text"} name="description" className='bg-slate-100 my-1' onChange={handleOnChange}></textarea>

          <label htmlFor='stock' className='p-1'>Stock</label>
          <input type={"text"} name="stock" className='bg-slate-100 my-1' onChange={handleOnChange} value={data.stock} />

          <label htmlFor='categorie' className='p-1'>Catégorie</label>
          <select name="categorie" className='bg-slate-100 my-1' id="categorie" value={data.categorie} onChange={handleOnChange}>
            <option></option>
            <option>Silver Tempest</option>
            <option>Vivid Voltage</option>
            <option>Chilling Reign</option>
            <option>Evolving Skies</option>
            <option>Fusion Strike</option>
            <option>Battle Styles</option>
            <option>Scarlet & Violet</option>
            <option>Black Star Promos</option>
            <option>McDonald's Collection 25th Anniversary</option>
            <option>McDonald's Match Battle 2023</option>
            <option>Coin</option>
          </select>

          <label htmlFor='image' className='p-1'>Image
            <div className='h-40 w-full bg-slate-200 rounded flex items-center justify-center cursor-pointer'>
              {
                data.image ?
                  <img src={data.image} alt={data.name} className='h-full' />
                  :
                  <span className='text-4xl'>
                    <FiUpload />
                  </span>
              }
              <input type={"file"} accept="image/*" id="image" onChange={uploadImage} className='hidden'></input>
            </div>
          </label>

          <label htmlFor='prix' className='p-1 my-1'>Prix</label>
          <input type={"text"} name="prix" className='bg-slate-100 my-1' value={data.prix} onChange={handleOnChange} />

          <button className="w-full px-4 my-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-black shadow-md hover:text-black hover:bg-white focus:outline-none focus:ring-2">Enregister</button>
        </form>


        <div>
          <button onClick={handleButtonClick} >
            'Afficher les produits en rupture de stock'
          </button>
          {outOfStockProducts.length > 0 && (
            <div>
              <h2>Produits en rupture de stock :</h2>
              {outOfStockProducts.map(product => (
                <div key={product._id}>
                  <p>{product.name}</p>
                  {/* Ajoutez d'autres détails ou actions selon vos besoins */}
                </div>
              ))}
            </div>
          )}
        </div>




      </div>
    ) : <div className='w-full px-4 my-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-black shadow-md hover:text-black hover:bg-white focus:outline-none focus:ring-2'>Vous n'êtes pas autorisé à accéder à cette page.</div>
  )

}

export default Addproduct