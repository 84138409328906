import React from 'react';

import { addCartItem, updateLastActivityTime } from '../redux/productSlice';

import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';


const HomeCard = ({ name, image, categorie, prix, description, id}) => {
    const dispatch = useDispatch();

    const handleAddCartProduct = async () => {
        const productId = id;
        dispatch(updateLastActivityTime());
        try {
            const response = await fetch(`/api/updateStock/${productId}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ quantity: 1 })
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.error || "Erreur lors de la mise à jour du stock");
            } else {
                const updateProduct = await response.json();
                dispatch(addCartItem(updateProduct.data));
                toast.success('Le produit a été ajouté avec succès.');
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className='relative flex flex-col text-gray-700 bg-white shadow-xl bg-clip-border rounded-xl w-96 mx-auto my-2'>
            {
                name &&
                <>
                    <div className='relative mx-4 mt-4 overflow-hidden bg-white bg-clip-border rounded-xl h-96'>
                        <img className="m-auto" alt={name} src={image} />
                    </div>
                    <div className='p-6'>
                        <div className='flex items-center justify-between mb-2'>
                            <p className='block font-sans text-base antialiased font-bold leading-relaxed text-blue-gray-900'>
                                {name}
                            </p>
                            <p className='block font-semibold text-end'>{categorie}</p>
                        </div>
                        <hr />
                        
                        <p className=' mt-8 mb-5 block w-3/4 font-sans text-sm antialiased font-normal text-gray-700 opacity-75'>{description}</p>
                            <p className='block font-sans text-base antialiased font-bold leading-relaxed text-blue-gray-900 text-right'>
                                {prix} CHF
                            </p>
                    </div>
                    <div className='p-6 pt-0'>
                        <button onClick={handleAddCartProduct} type="button" className='align-left select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3 px-6 rounded-lg shadow-gray-900 focus:opacity-[0.85] active:shadow-none block w-full bg-gray-300 text-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100'>
                            Ajouter au panier
                        </button>
                    </div>
                </>
            }
        </div>
    )
}

export default HomeCard