import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CartProduct from '../component/CartProduct';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from "react-icons/io";
import { FaUser } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { deleteAllCart, updateLastActivityTime } from '../redux/productSlice';
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from '../component/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { Helmet } from 'react-helmet';


const Panier = () => {
    const userData = useSelector((state) => state.user);
    const productCartItem = useSelector((state) => state.product.cartItem);
    const [frais, setFrais] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [stripePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY))


    const prixPanier = productCartItem.reduce(
        (acc, curr) => acc + parseFloat(curr.total),
        0
    );
    const totalQty = productCartItem.reduce(
        (acc, curr) => acc + parseInt(curr.quantity),
        0
    );

    const totalPrix = (prixPanier + parseFloat(frais)).toFixed(2);


    const handleModifierCompte = () => {
        navigate('/compte', { state: { fromCart: true } });
        dispatch(updateLastActivityTime());
    };

    const handleDeleteAll = () => {
        dispatch(deleteAllCart());
        dispatch(updateLastActivityTime());
        navigate("/");
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (totalQty > 51) {
            setFrais("7.00");
        } else if (totalQty > 10) {
            setFrais("2.90")
        } else if (totalQty >= 1) {
            setFrais("1.10")
        } else {
            setFrais("0")
        }
    }, [totalQty]);

    const options = {
        mode: 'payment',
        currency: 'chf',
        amount: 1000,
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false
        }
    };
    return (
        <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
            <Helmet>
                <title>Votre Panier | Pokémark Boutique de Cartes Pokémon</title>
                <meta
                    name="description"
                    content="Votre panier sur Pokémark contient les trésors de votre collection de cartes Pokémon. Ajoutez vos cartes favorites, vérifiez votre sélection et finalisez vos achats en toute simplicité. Ne manquez pas l'occasion de posséder les cartes les plus recherchées !"
                />
            </Helmet> 
            {productCartItem[0] ? (
                <>
                    <div className="flex justify-start item-start space-y-2 flex-col">
                        <h1 className="text-3xl mt-10 lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">Panier</h1>
                    </div>
                    <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
                        <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
                            <div className="flex flex-col justify-start items-start bg-gray-200 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
                                {productCartItem.map((el) => (
                                    <CartProduct
                                        key={el._id}
                                        id={el._id}
                                        name={el.name}
                                        image={el.image}
                                        categorie={el.categorie}
                                        quantity={el.quantity}
                                        total={el.total}
                                        prix={el.prix}
                                    />
                                ))}
                            </div>
                            <div className='w-full flex flex-col-reverse md:flex-row md:justify-between'>
                                <div className=''>
                                    <Link to={"/cartes"} className='w-full md:w-80 px-4 bg-gray-900 flex justify-between items-center lg:w-72 h-10 text-white hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 dark:hover:bg-gray-600'>
                                        <IoIosArrowRoundBack className='text-2xl' />
                                        <p className="text-md font-medium leading-5 ">Continuez vos Achats</p>
                                    </Link>
                                </div>
                                <button className='w-full mb-7 md:w-56 mt-2 py-3 hover:bg-gray-900 hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  border border-gray-800 text-base font-medium leading-3 text-gray-800 md:mt-0 ' onClick={handleDeleteAll}>Supprimer tous le panier</button>
                            </div>
                            <div className="flex justify-center md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                                <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full dark:bg-gray-900 space-y-6">
                                    <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">Résumé</h3>
                                    <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                                        <div className="flex justify-between items-center w-full">
                                            <p className="text-base dark:text-white leading-4 text-gray-800">Nombre de produits</p>
                                            <p className="text-base dark:text-gray-300 leading-4 text-gray-600">{totalQty}x</p>
                                        </div>
                                        <div className="flex justify-between w-full">
                                            <p className="text-base dark:text-white leading-4 text-gray-800">Sout-total</p>
                                            <p className="text-base dark:text-gray-300 leading-4 text-gray-600">CHF {prixPanier.toFixed(2)}</p>
                                        </div>
                                        <div className="flex justify-between items-center w-full">
                                            <p className="text-base dark:text-white leading-4 text-gray-800">Frais de Livraison</p>
                                            <p className="text-base dark:text-gray-300 leading-4 text-gray-600">CHF {frais}</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center w-full">
                                        <p className="text-base dark:text-white font-semibold leading-4 text-gray-800">Total</p>
                                        <p className="text-base dark:text-gray-300 font-semibold leading-4 text-gray-600">CHF {totalPrix}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full xl:w-96 flex justify-between items-center md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col">
                            {userData.email && (
                                <>
                                    <h3 className="text-xl font-semibold leading-5 text-gray-800">Livraison</h3>
                                    <div className="flex flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0 xl:space-y-12">
                                        <div className="flex flex-col justify-start items-start flex-shrink-0 mr-10">
                                            <div className="flex justify-center w-full md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                                                <FaUser className=' text-gray-900' />
                                                <div className="flex justify-start items-start flex-col space-y-2">
                                                    <p className="text-basefont-semibold leading-4 text-left text-gray-800">{`${userData.firstName} ${userData.lastName}`}</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-center text-gray-800 md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                                                <MdEmail />
                                                <p className="cursor-pointer text-sm leading-5 ">{userData.email}</p>
                                            </div>
                                        </div>
                                        <div className="flex xl:h-full items-stretch w-full flex-col">
                                            <div className="flex flex-col justify-center items-center space-y-4  md:space-y-0 md:flex-row lg:space-x-8 xl:space-x-0 xl:space-y-12">
                                                <div className="flex flex-col w-full justify-center items-center mt-8 space-y-2 md:mt-6 md:mb-4 md:items-end  xl:justify-start ">
                                                    <p className="text-base xl:w-full font-semibold xl:text-left  text-gray-800">Adresse de Livraison</p>
                                                    <p className="w-48 lg:w-full  xl:text-left  text-center md:text-right text-sm leading-5 text-gray-600">{userData.adresse}</p>
                                                    <p className="w-48 lg:w-full xl:text-left text-center md:text-right  text-sm leading-5 text-gray-600">{userData.ville}</p>
                                                    <p className="w-48 lg:w-full xl:text-left  text-center md:text-right  text-sm leading-5 text-gray-600">{userData.pays}</p>
                                                </div>
                                            </div>
                                            <div className="flex w-full justify-center items-center m-auto md:m-0 md:justify-end xl:justify-start xl:mt-1 ">
                                                <button onClick={handleModifierCompte} className="mt-6 py-3  hover:bg-gray-900 hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  border border-gray-800 w-56 text-base font-medium leading-3 text-gray-800 md:mt-0 2xl:w-full "> Modifier les détails</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </>)}
                            <div className="w-full flex flex-col max-w-3xl m-auto px-1 py-6">
                                <div className='mt-4'>
                                    <Elements stripe={stripePromise} options={options} >
                                        <CheckoutForm totalPrice={totalPrix} cartItem={productCartItem} />
                                    </Elements>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="flex justify-start item-start space-y-2 flex-col">
                    <h1 className="text-3xl mt-10 lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">Votre panier est vide</h1>
                </div>
            )}
        </div>
    )
}
export default Panier