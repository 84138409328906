import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import Contact from './page/Contact';
import About from './page/About';
import Cartes from './page/Cartes';
import Home from './page/Home';
import Login from './page/Login';
import Signup from './page/Signup';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import Compte from './page/Compte';
import Addproduct from './page/Addproduct';
import Carte from './page/Carte';
import TermesCondition from './page/TermesCondition';
import Privacy from './page/Privacy';
import Panier from './page/Panier';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route path="cartes" element={<Cartes />} />
      <Route path="cartes/:categorie" element={<Cartes />} />
      <Route path="carte/:id" element={<Carte />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="login" element={<Login />} />
      <Route path="compte" element={<Compte />} />
      <Route path="addproduct" element={<Addproduct />} />
      <Route path="signup" element={<Signup />} />
      <Route path="termsconditions" element={<TermesCondition />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="panier" element={<Panier />} />
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
    </PersistGate>
  </Provider>
);