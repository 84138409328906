import { combineReducers } from 'redux';
import productReducer from './productSlice';
import userSliceReducer from './userSlice';



const rootReducer = combineReducers({
    user : userSliceReducer,
    product : productReducer,
});

export default rootReducer;