import React, { useEffect} from 'react';
import cards from "../media/cards.webp";
import pika from "../media/pikachu.webp";
import boosters from "../media/boosters.webp";
import HomeCard from '../component/HomeCard';
import { useSelector } from 'react-redux';
import CardFeature from '../component/CardFeature';
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';




const Home = () => {
  const productData = useSelector((state) => state.product.productList);
  const homeProductCartList = productData.slice(-3);
  const homeProductCartListCat = productData.filter((el) => el.categorie === "Scarlet & Violet", []).slice(-6);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className='w-screen'>
    <Helmet>
      <title>
        PokéMark, Cartes Pokémon à Vendre | Boutique en Ligne
      </title>
      <meta name="description" 
      content = "Explorez notre collection de cartes Pokémon à vendre sur notre boutique en ligne. Découvrez des cartes rares et recherchées. Achetez vos cartes favorites sur PokéMark !"/>
    </Helmet>
      <div className='mx-auto mt-10 mb-36 container flex justify-center items-center py-12 sm:px-6 2xl:px-0 shadow-xl bg-white'>
        <div className='flex flex-col lg:flex-row justify-center items-center space-y-6 lg:space-y-0'>
          <div className= ' lg:ml-10 sm:w-auto flex flex-col justify-start items-start'>
            <div className='p-4'>
              <p className='text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                Bienvenue sur Pokémark
              </p>
            </div>
            <div className='p-4 lg:w-4/5 xl:w-3/5'>
              <p className='text-base leading-6 text-gray-600'>
                Notre passion pour l'univers Pokémon se reflète dans chaque carte que nous proposons. Explorez notre collection.
              </p>
            </div>
            <div className='mt-16 w-full lg:p-4 '>
              <Link to={"cartes"} className='px-4 bg-gray-900 flex justify-between items-center lg:w-72 h-14 text-white hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 dark:hover:bg-gray-600'>
                <p className="text-xl font-medium leading-5 ">Notre Catalogue</p>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path d="M6.66663 16H25.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20 21.3333L25.3333 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M20 10.6667L25.3333 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Link>
            </div>
          </div>
          <div className='flex flex-col sm:flex-row justify-center items-center sm:space-x-5 xl:space-x-8 space-y-4 sm:space-y-0'>
            <div>
              <img className="hidden max-w-xs lg:block" src={pika} alt="sofa" loading="lazy"/>
              <img className="w-auto sm:max-w-[300px] lg:hidden" src={pika} alt="sofa" loading="lazy"/>
            </div>
            <div className="flex flex-col justify-center items-center space-y-4 sm:space-y-0 lg:space-y-5 xl:space-y-8">
              <div>
                <img className="hidden max-w-xs lg:block" src={cards} alt="cards" loading="lazy" />
              </div>
              <div>
                <img className="hidden max-w-xs lg:block" src={boosters} alt="boosters" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full sm:w-4/5 m-0 sm:mx-auto mt-16 pt-40 flex flex-wrap items-center justify-center bg-contain sm:bg-auto bg-scarletViolet bg-no-repeat bg-top '>
        {
          homeProductCartListCat[0] ? (
            homeProductCartListCat.map(el => {
              return (
                <CardFeature
                  key={el._id}
                  name={el.name}
                  image={el.image}
                  prix={el.prix}
                  categorie={el.categorie}
                  description={el.description}
                  id={el._id}
                  stock={el.stock}
                />
              )
            })) : <div className='mx-auto my-10'>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        }
      </div>
      <div className=" my-44 flex w-screen">
        <div className='flex w-screen h-20 shadow-2xl'>

          <Link to={"cartes/Battle Styles"} className="slide relative flex-auto bg-cover bg-center transition-all duration-500 ease-in-out hover:flex-grow-5 bg-battlestyles" >
          </Link>
          <Link to={"cartes/Chilling Reign"} className="slide relative flex-auto bg-cover bg-center transition-all duration-500 ease-in-out hover:flex-grow-5 bg-chillingreign" ></Link>
          <Link to={"cartes/Evolving Skies"} className="slide relative flex-auto bg-cover bg-center transition-all duration-500 ease-in-out hover:flex-grow-5 bg-evolvingskies" ></Link>
          <Link to={"cartes/Fusion Strike"} className="slide relative flex-auto bg-cover bg-center transition-all duration-500 ease-in-out hover:flex-grow-5 bg-fusionstrike" ></Link>
          <Link to={"cartes/McDonald's Collection 25th Anniversary"} className="slide relative flex-auto bg-cover bg-center transition-all duration-500 ease-in-out hover:flex-grow-5 bg-mcdonald" ></Link>
          <Link to={"cartes/Scarlet & Violet"} className="slide relative flex-auto bg-cover bg-center transition-all duration-500 ease-in-out hover:flex-grow-5 bg-scarletViolet" ></Link>
          <Link to={"cartes/Silver Tempest"} className="slide relative flex-auto bg-cover bg-center transition-all duration-500 ease-in-out hover:flex-grow-5 bg-silvertempest" ></Link>
          <Link to={"cartes/Vivid Voltage"} className="slide relative flex-auto bg-cover bg-center transition-all duration-500 ease-in-out hover:flex-grow-5 bg-vividvoltage" ></Link>
        </div>
      </div>
      <div className='mt-40'>
        <h5 className='mb-10 text-center text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>Nouveautés</h5>
        <div className='flex flex-wrap '>
          {
            homeProductCartList[0] ?
              homeProductCartList.map((el) => {
                return (
                  <HomeCard
                    name={el.name}
                    image={el.image}
                    prix={el.prix}
                    categorie={el.categorie}
                    description={el.description}
                    key={el._id}
                    id={el._id}
                    stock={el.stock}
                  />
                )
              }) :
              <div className='mx-auto my-10'>
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                />
              </div>
          }
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home