import React from 'react'
import { IoTrash } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import { deleteCartItem, updateLastActivityTime } from '../redux/productSlice';
import toast from 'react-hot-toast';


const CartProduct = ({ id, name, image, categorie, quantity, total, prix }) => {
    const dispatch = useDispatch();


    const handleDeleteItem = async (e) => {
        e.preventDefault();
        dispatch(updateLastActivityTime());
        const productId = id;

        try {
            await fetch(`/restoreStock/${productId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ quantity: quantity })
            });
            dispatch(deleteCartItem(productId));
            toast.success("Le produit a bien été retirer du panier d'achat.");
        } catch (error) {
            toast.error("Un problème est survenu. Réessayer");

        }

    }

    return (
        <div className=" relative mt-4 md:mt-6 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full">
            <div className="pb-4 md:pb-8 w-full md:w-40">
                <img className="w-full hidden md:block" src={image} alt={name} />
                <img className="w-full h-60 object-contain md:hidden" src={image} alt={name} />
            </div>

            <div className="border-b border-gray-400 md:flex-row flex-col flex justify-between items-start w-full pb-8 space-y-4 md:space-y-0">
                <div className="w-full flex flex-col justify-start items-start space-y-8">
                    <h3 className="text-lg xl:text-2xl text-gray-800 font-semibold leading-6">{name}</h3>
                    <div className="flex justify-start items-start flex-col space-y-2">
                        <p className="text-sm leading-none text-gray-800">{categorie}</p>
                    </div>
                </div>
                <div className="flex justify-between space-x-8 items-start w-full">
                    <p className="text-base  xl:text-lg leading-6">CHF {prix}</p>
                    <p className="text-base xl:text-lg leading-6 text-gray-800">{quantity}x</p>
                    <p className="text-base xl:text-lg font-semibold leading-6 text-gray-800">{total}</p>
                    <div>
                        <IoTrash className='md:text-xl cursor-pointer' onClick={handleDeleteItem} />
                    </div>
                </div>
            </div>
        </div>


    )
}

export default CartProduct