import React, { useEffect } from 'react'
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';

const TermesCondition = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
        <Helmet>
        <title>Termes et Conditions | Pokémark Boutique de Cartes Pokémon</title>
        <meta
          name="description"
          content="Consultez les termes et conditions de Pokémark pour comprendre nos accords, règles et responsabilités. Assurez-vous de lire attentivement cette section pour garantir une utilisation équitable et agréable de notre boutique en ligne de cartes Pokémon."
        />
      </Helmet>
            <div className='m-8 md:m-20 text-center lg:text-start'>

                <div className='px-4 bg-gray-900 flex justify-between items-center w-full lg:w-72 h-14 text-white hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 dark:hover:bg-gray-600'>
                    <p className="text-xl font-medium leading-5 ">Termes et Conditions</p>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Introduction
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Bienvenue sur PokéMark.ch, exploitant depuis Lucens, Suisse. Veuillez lire attentivement les présents Termes et Conditions avant d'utiliser notre site web.                        </p>
                    </div>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Utilisation du Site
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='leading-6 text-gray-600'>
                            En accédant à ce site, vous acceptez de vous conformer à ces Termes et Conditions. Si vous n'êtes pas d'accord avec certaines parties de ces termes, veuillez ne pas utiliser notre site.                        </p>
                    </div>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Commandes et Transactions
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Les commandes passées sur notre site sont soumises à la disponibilité des produits. Nous nous réservons le droit de refuser ou d'annuler toute commande pour quelque raison que ce soit, y compris si des informations incorrectes ont été fournies ou en cas de suspicion de fraude.
                        </p>
                    </div>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Livraison et Retours
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Les détails concernant les frais de livraison, les délais de livraison estimés et les politiques de retour sont disponibles sur notre page dédiée aux "Livraison et Retours". Nous nous efforçons de fournir des informations précises et de traiter les commandes dans les délais les plus brefs.
                        </p>
                    </div>
                </div>


                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Responsabilités de l'Acheteur
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            En passant commande, vous garantissez que vous avez l'âge légal pour acheter des produits sur notre site. Vous êtes responsable de fournir des informations exactes pour la livraison et de respecter toutes les lois locales relatives à l'achat et à la possession de produits similaires.
                        </p>
                    </div>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Confidentialité
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Consultez notre politique de confidentialité pour comprendre comment nous collectons, utilisons et protégeons vos informations personnelles.
                        </p>
                    </div>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Loi applicable
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Ces Termes et Conditions sont régis par les lois de la Suisse. Tout litige découlant de l'utilisation de notre site sera soumis à la compétence exclusive des tribunaux suisses.
                        </p>
                    </div>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Contact
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Pour toute question ou préoccupation concernant ces Termes et Conditions, veuillez nous contacter à a.wirth@webryn.com.
                        </p>
                    </div>
                </div>
                <p  className='text-xl mt-8 font-semibold leading-9 text-gray-800 dark:text-black'>
                    Dernière mise à jour : 5 décembre 2023
                    </p>
            </div>
            <Footer />
        </>
    )
}

export default TermesCondition