import { useEffect, useState } from "react";
import Header from './component/Header';
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { setDataProduct } from "./redux/productSlice";
import { useDispatch } from "react-redux";


function App() {
  const dispatch = useDispatch();
  const [showPopup, setShowPopUp] = useState(false);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };


  useEffect(() => {
    const paymentSuccess = getCookie("paymentSuccess");

    if (paymentSuccess === "true") {
      setShowPopUp(true);

      document.cookie = 'paymentSuccess=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    }
  }, []);


  useEffect(() => {
    (async () => {
      const res = await fetch("api/product")
      const resData = await res.json()
      dispatch(setDataProduct(resData))
    })()
  }, [dispatch]);



  return (

    <>
      <Toaster />
      <div>
        <Header />
        <main className="pt-16 bg-slate-100 min-h-[calc(100vh)]">
          {showPopup && (
            <div className=" overflow-y-auto overflow-x-hidden fixed z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
              <div className="relative p-4 w-full max-w-2xl max-h-full">

                <div className="relative bg-white rounded-lg shadow">

                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 ">
                      Confirmation de Commande
                    </h3>
                    <button onClick={() => setShowPopUp(false)} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white ">
                      <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                      </svg>
                    </button>
                  </div>
                  <div className="p-4 md:p-5 space-y-4">
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Félicitations ! Votre commande a été traitée avec succès. Nous vous remercions pour votre achat.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Votre commande sera rapidement préparée et expédiée. Le statut vous sera communiqué par email.
                      Nous vous remercions de faire confiance à notre boutique en ligne. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
          }
          <Outlet />
        </main>
      </div>
    </>

  );
}

export default App;
