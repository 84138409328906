import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import { deleteAllCart, updateLastActivityTime } from './productSlice';

const expireMiddleware = (store) => (next) => (action) => {
  // Intercepter toutes les actions
  const currentTime = new Date().getTime();
  const lastActivityTime = store.getState().product.lastActivityTime;
  if (action.type !== "product/updateLastActivityTime") {
    store.dispatch(updateLastActivityTime()); // Mettre à jour lastActivityTime sauf si c'est déjà mis à jour
  }
  if (lastActivityTime && currentTime - lastActivityTime > 200 * 60 * 1000) {
    store.dispatch(deleteAllCart());
  
  }
  return next(action);
};



const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["cartItem"],
 expireIn: 200 * 60 * 1000
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [expireMiddleware],
});

const persistor = persistStore(store);


export { store, persistor };