import React from 'react';
import pokeball from "../media/pokeball.webp";
import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <footer className='bg-white lg:grid lg:grid-cols-5 mt-32'>
            <div className='relative block h-32 lg:col-span-2 lg:h-full cursor-default'>
                <img className='absolute inset-0 h-full w-full object-cover' src={pokeball} alt="pokeball" loading="lazy" />
            </div>
            <div className='px-4 py-16 sm:px-6 lg:col-span-3 lg:px-8'>
                <div className='grid grid-cols-1 gap-8 sm:grid-cols-2'>
                    <div>
                        <p>
                            <span>Contactez-nous</span>
                            <a href='mailto:a.wirth@webryn.com' className=' cursor-pointer block text-2xl font-medium text-gray-900 hover:opacity-75 sm:text-3xl'>
                                a.wirth@webryn.com
                            </a>
                        </p>
                        <ul className='mt-8 space-y-1 text-sm text-gray-700'>
                            <li>Lundi à Vendredi : 08h à 18h</li>
                            <li>Samedi : 08h à 12h</li>
                        </ul>
                    </div>
                    <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
                        <div>
                            <p className='font-medium text-gray-900'>Services</p>
                            <ul className=' mt-6 space-y-4 '>
                                <li>
                                    <Link to={"/"} className='text-gray-700 transition hover:opacity-75'>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/cartes"} className='text-gray-700 transition hover:opacity-75'>
                                       Cartes
                                    </Link>
                                </li>

                            </ul>
                        </div>
                        <div>
                            <p className='font-medium text-gray-900'>Pokémark</p>
                            <ul className='mt-6 space-y-4'>
                                <li>
                                    <Link to={"/contact"} className='text-gray-700 transition hover:opacity-75'>
                                        Contact
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/about"} className='text-gray-700 transition hover:opacity-75'>
                                        À Propos
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='mt-12 border-t border-gray100 pt-12'>
                    <div className='sm:flex sm:items-center sm:justify-between'>
                        <ul className='flex flex-wrap gap-4 text-xs'>
                            <li>
                                <Link to={"/termsconditions"}>
                                    Termes et Conditions
                                </Link>
                            </li>
                            <li>
                                <Link to={"/privacy"}>
                                    Politique de Confidentialité
                                </Link>
                            </li>
                        </ul>

                        <p className='mt-8 text-xs text-gray-500 sm:mt-0'>
                        <a href='https://www.webryn.com' target="_blank" rel="noreferrer" >&copy; 2023. Webryn</a> All rights reserved.
                        </p>

                    </div>

                </div>

            </div>

        </footer>
    )
}

export default Footer