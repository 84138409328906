import React, { useState } from 'react';
import signUpGif from "../media/signup.gif";
import { BiShowAlt, BiHide } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet';




const Signup = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    const handleShowPassword = () => {
        setShowPassword(prev => !prev);
    }

    const handleConfirmPassword = () => {
        setConfirmPassword(prev => !prev);
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const email = data.email.toLowerCase();
        const { firstName, lastName, password } = data;

        if (firstName && email && password && lastName && data.confirmPassword) {
            if (password === data.confirmPassword) {
                const fetchData = await fetch('api/signup', {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify({ firstName, lastName, email, password })
                });
                const dataResponse = await fetchData.json();


                document.getElementById("errorMessage").innerText = dataResponse.message;
                if (dataResponse.status === 201) {
                    toast.success("Vous avez été enregistré avec succès.");
                    navigate("/login");
                }
                else {
                    toast.error(dataResponse.message);
                }

            }
            else {
                document.getElementById("errorMessage").innerText = "Les mots de passe ne correspondent pas.";
            }
        } else {
            document.getElementById("errorMessage").innerText = "Veuillez renseigner tous les champs.";

        }
    }
    return (
        <div className='p-3 md:p-4 '>
            <Helmet>
                <title>Inscrivez-vous sur Pokémark | Boutique de Cartes Pokémon</title>
                <meta
                    name="description"
                    content="Rejoignez la communauté Pokémark en vous inscrivant dès maintenant ! Créez votre compte pour accéder à des fonctionnalités exclusives, suivre vos commandes, et explorer une vaste collection de cartes Pokémon. L'aventure Pokémon vous attend, inscrivez-vous aujourd'hui."
                />
            </Helmet>
            <div className='w-full max-w-sm bg-white m-auto flex flex-col p-4'>
                <div className='w-20 overflow-hidden rounded-full drop-shadow-md shadow-md m-auto relative'>
                    <img className='w-full' loading="lazy" src={signUpGif} alt="gif d'un bonhomme qui salue de la main" />
                </div>
                <form className='py-4 flex flex-col w-full' onSubmit={handleSubmit}>
                    <label htmlFor='firstName'>Prénom</label>
                    <input maxLength="30" type={"text"} id="firstName" name="firstName" className='mt-1 mb-4 w-full bg-slate-200 px-2 py-1 rounded focus-within:outline-blue-200' value={data.firstName} onChange={handleOnChange} />

                    <label htmlFor='lastName'>Nom</label>
                    <input maxLength="30" type={"text"} id="lastName" name="lastName" className='mt-1 mb-4 w-full bg-slate-200 px-2 py-1 rounded focus-within:outline-blue-200' value={data.lastName} onChange={handleOnChange} />

                    <label htmlFor='email'>Email</label>
                    <input maxLength="50" type={"email"} id="email" name="email" className='mt-1 mb-4 w-full bg-slate-200 px-2 py-1 rounded focus-within:outline-blue-200' value={data.email} onChange={handleOnChange} />

                    <label htmlFor='password'>Mot de passe</label>
                    <div className='flex px-2 py-1 bg-slate-200 rounded mt-1 mb-4 focus-within:outline focus-within:outline-blue-200' value={data.password} onChange={handleOnChange} >
                        <input maxLength="30" type={showPassword ? "text" : "password"} id="password" name="password" className='w-full bg-slate-200 border-none outline-none ' />
                        <span className='flex text-xl cursor-pointer' onClick={handleShowPassword}>
                            {
                                showPassword ? <BiShowAlt /> : <BiHide />
                            }
                        </span>


                    </div>
                    <label htmlFor='confirmPassword'>Confirmer mot de passe </label>
                    <div className='flex px-2 py-1 bg-slate-200 rounded mt-1 mb-4 focus-within:outline focus-within:outline-blue-200'>
                        <input maxLength="30" type={confirmPassword ? "text" : "password"} id="confirmPassword" name="confirmPassword" className='w-full bg-slate-200 border-none outline-none' value={data.confirmPassword} onChange={handleOnChange} />
                        <span className='flex text-xl cursor-pointer' onClick={handleConfirmPassword}>
                            {
                                confirmPassword ? <BiShowAlt /> : <BiHide />
                            }
                        </span>

                    </div>
                    <div className="text-center text-sm mt-4 text-red-600 " id="errorMessage"></div>
                    <button className='max-w-[160px] w-full m-auto bg-blue-300 hover:bg-blue-400 cursor-pointer text-white text-xl font-medium text-center py-1 rounded mt-4'>S'inscrire</button>
                </form>
                <p className='text-left text-sm mt-4'>Déjà un compte ? <Link to={"/login"} className='text-green-600 underline'>Connectez-vous ici</Link></p>
            </div>
        </div>
    )
}

export default Signup