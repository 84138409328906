import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { FaUser } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { useSelector } from 'react-redux';


const CheckoutForm = ({ totalPrice, cartItem }) => {
    const stripe = useStripe();
    const userData = useSelector((state) => state.user);
    const elements = useElements()
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);


    const handleSubmit = async (e, userData) => {
        e.preventDefault();
        setLoading(true);
        if (elements == null) {
            return;
        }
        const { error: submitError } = await elements.submit();
        if (submitError) {
            setErrorMessage(submitError.message);
            return;
        }
       
        const orderData = userData ?  {
            userId: userData._id,
            email:userData.email,
            name: `${userData.firstName} ${userData.lastName}`,
            adresse: userData.adresse,
            ville: userData.ville,
            pays: userData.pays,
            cartItems : cartItem,
            totalPrice : totalPrice
        }:
        {
            email: e.target.email.value,
            name: e.target.name.value,
            adresse: e.target.adresse.value,
            ville: e.target.ville.value,
            pays: e.target.pays.value,
            cartItems: cartItem,
            totalPrice: totalPrice
        };

        const res = await fetch('api/process-payment', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ orderData }),
        });
        setLoading(false)
        const { client_secret: clientSecret } = await res.json();

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            clientSecret,
            confirmParams: {
                return_url: `${process.env.REACT_APP_FRONTEND_URL}/api/success`,
            },
        });

        if (error) {
            setErrorMessage(error.message);
        }
    };

    return (

                <form className='w-full m-auto'  onSubmit={(e) => handleSubmit(e)}>

            {!userData.email && (

                        <div className="flex flex-col md:flex-row xl:flex-col justify-center md:justify-between h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0">
                            <div className="flex flex-col justify-start items-start flex-shrink-0 mr-10">
                            <h3 className="text-xl font-semibold leading-5 text-gray-800">Détail</h3>
                                <div className="flex justify-center w-full md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                                    <FaUser className=' text-gray-900' />
                                    <div className="flex justify-start items-start flex-col space-y-2">
                                        <input className="p-1" name="name" id="name" placeholder='Nom' required />
                                    </div>
                                </div>
                                <div className="flex justify-center text-gray-800 md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                                    <MdEmail />
                                    <input className="p-1" placeholder='Email' name="email" id="email" required />
                                </div>
                            </div>
                            <div className="flex xl:h-full flex-col">
                                <div className="flex flex-col justify-center items-center space-y-4 md:justify-items-end md:space-y-0 md:flex-row xl:justify-start lg:space-x-8 xl:space-x-0 xl:space-y-12">
                                    <div className="flex flex-col justify-center items-center mt-8 space-y-2 md:mt-8 md:mb-4 md:items-end xl:mt-3 lg:w-full xl:justify-start ">
                                        <p className="text-base xl:w-full font-semibold xl:text-left  text-gray-800">Adresse de Livraison</p>
                                        <input id="adresse" name="adresse" placeholder="Adresse" className=" p-1 w-60 xl:w-full  xl:text-left  text-center md:text-right text-sm leading-5 text-gray-600" />
                                        <input id="ville" name="ville" placeholder='Code Postal et Ville' className="p-1 w-60 xl:w-full  xl:text-left  text-center md:text-right text-sm leading-5 text-gray-600" />
                                        <input id="pays" name="pays" placeholder='Pays' className="p-1 w-60 xl:w-full  xl:text-left  text-center md:text-right text-sm leading-5 text-gray-600" />
                                    </div>
                                </div>
                            </div>
                        </div>
                
                )}
                <div>
                    <h3 className="text-xl font-semibold leading-5 text-gray-800 mt-10 mb-3">Paiement</h3>
                    <PaymentElement />
                    <button  className='p-5 mt-3 mx-auto bg-gray-900 flex w-40 lg:w-full justify-center text-center items-center h-8 text-white hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-800' type="submit" disabled={!stripe || !elements || loading}>
                        Payer {totalPrice} CHF
                    </button>
                    {/* Show error message to your customers */}
                    {errorMessage && <div>{errorMessage}</div>}
                </div>
                </form>
    );
};

export default CheckoutForm;