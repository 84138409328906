import React, { useState } from 'react';
import signUpGif from "../media/signup.gif";
import { BiShowAlt, BiHide } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { loginRedux } from '../redux/userSlice';
import { Helmet } from 'react-helmet';



const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleShowPassword = () => {
    setShowPassword(prev => !prev);
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = data.email.toLowerCase();
    const password = data.password;

    try {
      if (!email && !password) {
        setErrorMessage("Veuillez remplir tous les champs.");
        return toast.error("Veuillez remplir tous les champs.");
      }

      const fetchData = await fetch('api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email, password}),
      });

      const dataRes = await fetchData.json();
    

      if (dataRes.error) {
        toast.error(dataRes.message);
      } else {
        dispatch(loginRedux(dataRes));
        toast.success(dataRes.message);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='p-3 md:p-4 '>
      <Helmet>
      <title>Connectez-vous à Pokémark | Boutique de Cartes Pokémon</title>
        <meta
          name="description"
          content="Connectez-vous à votre compte Pokémark pour accéder à votre profil, suivre vos commandes et gérer votre collection de cartes Pokémon. Si vous n'avez pas encore de compte, inscrivez-vous dès maintenant pour vivre une expérience de collection unique."
        />
      </Helmet>
      <div className='w-full max-w-sm bg-white m-auto flex flex-col p-4'>
        <div className='w-20 overflow-hidden rounded-full drop-shadow-md shadow-md m-auto'>
          <img className='w-full' alt="gif d'un bonhomme qui fait bonjour de la main" src={signUpGif} loading="lazy"  />
        </div>
        <form className='py-4 flex flex-col w-full' onSubmit={handleSubmit}>
          <label htmlFor='email'>Email</label>
          <input maxLength="50" type={"email"} id="email" name="email" className='mt-1 mb-4 w-full bg-slate-200 px-2 py-1 rounded focus-within:outline-blue-200' value={data.email} onChange={handleOnChange} />

          <label htmlFor='password'>Mot de passe</label>
          <div className='flex px-2 py-1 bg-slate-200 rounded mt-1 mb-4 focus-within:outline focus-within:outline-blue-200' value={data.password} onChange={handleOnChange} >
            <input maxLength="30" type={showPassword ? "text" : "password"} id="password" name="password" className='w-full bg-slate-200 border-none outline-none ' />
            <span className='flex text-xl cursor-pointer' onClick={handleShowPassword}>
              {
                showPassword ? <BiShowAlt /> : <BiHide />
              }
            </span>
          </div>
          {errorMessage && <div className='text-center text-sm mt-4 text-red-600'>{errorMessage}</div>}
          <button className='max-w-[160px] w-full m-auto bg-blue-300 hover:bg-blue-400 cursor-pointer text-white text-xl font-medium text-center py-1 rounded mt-4'>Se connecter</button>
        </form>
        <p className='text-left text-sm mt-4'>Pas déjà inscrit ? <Link to={"/signup"} className='text-green-600 underline'>S'inscrire ici</Link></p>
      </div>
    </div>
  )
}


export default Login