import React, { useEffect } from 'react'
import Footer from '../component/Footer'
import { Helmet } from 'react-helmet';


const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Politique de Confidentialité | Pokémark Boutique de Cartes Pokémon</title>
                <meta
                    name="description"
                    content="Découvrez comment Pokémark protège vos informations personnelles grâce à notre politique de confidentialité. Consultez cette page pour comprendre comment nous collectons, utilisons et protégeons vos données lors de votre expérience sur notre boutique en ligne de cartes Pokémon."
                />
            </Helmet>
            <div className='m-8 md:m-20 text-center lg:text-start'>

                <div className='px-4 bg-gray-900 flex justify-between items-center w-full lg:w-72 h-14 text-white hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 dark:hover:bg-gray-600'>
                    <p className="text-xl font-medium leading-5 ">Politique de Confidentialité</p>
                </div>
                <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                    <p className='text-base leading-6 text-gray-600'>
                        La présente Politique de Confidentialité régit la manière dont Pokémark.ch collecte, utilise, stocke et partage les informations que vous nous fournissez via notre site web.
                    </p>
                </div>


                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Collecte d'Informations
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Nous collectons des informations personnelles telles que votre nom, adresse e-mail, adresse de livraison et informations de paiement lorsque vous effectuez un achat sur notre site. Nous pouvons également recueillir des données non personnelles, telles que votre adresse IP et le type de navigateur que vous utilisez, pour améliorer votre expérience sur notre site.
                        </p>
                    </div>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Utilisation des Informations
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='leading-6 text-gray-600'>
                            Les informations personnelles que nous recueillons sont utilisées pour traiter vos commandes, vous fournir des informations sur nos produits et services, et personnaliser votre expérience sur notre site. Nous ne partageons pas vos informations avec des tiers, sauf dans les cas nécessaires pour le traitement des transactions ou lorsque requis par la loi.
                        </p>
                    </div>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Sécurité des Données
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Nous mettons en œuvre des mesures de sécurité pour protéger vos informations contre tout accès non autorisé, divulgation, altération ou destruction. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet, ni aucune méthode de stockage électronique, n'est totalement sécurisée.
                        </p>
                    </div>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Cookies
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Notre site utilise des cookies pour améliorer votre expérience de navigation. Vous pouvez choisir de désactiver les cookies dans les paramètres de votre navigateur, mais cela peut affecter certaines fonctionnalités de notre site.
                        </p>
                    </div>
                </div>


                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Droits des Utilisateurs
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Vous avez le droit d'accéder, de rectifier ou de supprimer vos informations personnelles. Si vous souhaitez exercer ces droits, veuillez nous contacter à a.wirth@webryn.com.
                        </p>
                    </div>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Modifications de la Politique de Confidentialité
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Nous nous réservons le droit de mettre à jour cette Politique de Confidentialité à tout moment. Les modifications seront publiées sur cette page avec la date de la dernière mise à jour.
                        </p>
                    </div>
                </div>

                <div >
                    <p className='mt-10 text-3xl xl:text-4xl font-semibold leading-9 text-gray-800 dark:text-black'>
                        Contact
                    </p>
                    <div className='mt-4 lg:w-4/5 xl:w-3/5'>
                        <p className='text-base leading-6 text-gray-600'>
                            Pour toute question concernant notre Politique de Confidentialité, veuillez nous contacter à a.wirth@webryn.com.
                        </p>
                    </div>
                </div>


                <p className='text-xl mt-8 font-semibold leading-9 text-gray-800 dark:text-black'>
                    Dernière mise à jour : 5 décembre 2023
                </p>
            </div>
            <Footer />
        </>
    )
}

export default Privacy