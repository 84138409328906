import React, { useEffect, useState } from 'react'
import squirtle from "../media/squirtle.webp";
import Footer from "../component/Footer";
import toast from "react-hot-toast";
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const [status, setStatus] = useState("Envoyer");
  const userData = useSelector((state) => state.user);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Envoi en cours...");
    const { name, email, message } = e.target.elements;
    const details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    const response = await fetch("api/contact-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    });
    const result = await response.json();
    if(result.erreur){
      setStatus(result.status);
      toast.error(result.status);
    }else{
      setStatus("Envoyé avec succès");
      toast.success(result.status);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);



  return (
    <>
    <Helmet>
    <title>Contactez Pokémark | Boutique de Cartes Pokémon</title>
    <meta
          name="description"
          content="Contactez-nous pour toute question, suggestion ou préoccupation. Pokemark est là pour vous fournir un service client exceptionnel. Utilisez les informations ci-dessous pour nous joindre et faites partie de notre communauté passionnée de collectionneurs Pokémon."
        />
    </Helmet>
    <div className="flex flex-wrap w-full mt-10">
      <div className="flex flex-col w-full md:w-1/2">
        <div className='flex flex-col justify-center px-8 pt-8 my-auto md:justify-start md:pt-0 md:px-24 lg:px-32'>
          <p className='text-3xl text-center'>
            Votre Message
          </p>
          <form onSubmit={handleSubmit}  method='post' className='flex flex-col pt-3 md:pt-8'>
            <div className='flex flex-col pt-4 mb-12'>
              <div className='flex relative'>
                <input className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder={userData.email[0] ? userData.firstName : "Nom"} value={userData.email[0] && userData.firstName} readOnly={userData.email[0]?true : false} type='text' id="name" name="name" />
              </div>
            </div>

            <div className='flex flex-col pt-4 mb-12'>
              <div className='flex relative'>
                <input className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder={userData.email[0] ? userData.email : "Email"} value={userData.email[0] && userData.email}  readOnly={userData.email[0]?true : false} type='email' id="email" name="email" required />
              </div>
            </div>

            <div className='flex flex-col pt-4 mb-12'>
              <div className='flex relative'>
                <textarea id="message" required cols="10" name='message' placeholder='Message' className=' h-72 flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent'>
                </textarea>           
              </div>
            </div>
            <button type="submit" className="w-full px-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-black shadow-md hover:text-black hover:bg-white focus:outline-none focus:ring-2">
              <span className="w-full">
                {status}
              </span>
            </button>
          </form>
        </div>
      </div>
      <div className="w-1/2 shadow-2xl">
        <img className="hidden object-cover w-full h-screen md:block" alt="pokemon" src={squirtle} loading="lazy" />
      </div>
    </div>
    <Footer />
    </>
  )
}

export default Contact