import React from 'react';
import { IoIosAdd } from "react-icons/io";
import { Link } from 'react-router-dom';
import { addCartItem, updateLastActivityTime } from '../redux/productSlice';
import {useDispatch} from "react-redux";
import toast from 'react-hot-toast';


const CardFeature = ({ name, image, prix, categorie, description, id }) => {
    const dispatch = useDispatch();
 
    const handleAddCartProduct = async () => {
        dispatch(updateLastActivityTime());
        const productId = id;
        try {
            const response = await fetch(`/api/updateStock/${productId}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ quantity: 1 })
            });
    
            if (!response.ok) {
                toast.error("Produit en rupture de stock");
            } else {
                const updateProduct = await response.json();
                dispatch(addCartItem(updateProduct.data));
                toast.success('Le produit a été ajouté avec succès.');
            }
        } catch (error) {
            toast.error("Produit en rupture de stock");
        }
    }

    return (

        <div className='p-1 sm:p-6 relative'>
            <div className='relative flex max-w-md bg-white shadow-2xl overflow-hidden'>
                <div className='w-1/2 sm:1/3 '>
                    <img className='w-full h-full object-contain' src={image} alt={name} />
                </div>
                <div className='w-2/3 p-4 relative'>
                    <h1 className='text-gray-900 font-bold h-14 text-sm sm:text-lg'>{name}</h1>
                    <h2 className='text-gray-900 pt-1 h-10 text-xs sm-text-lg font-semibold'>{categorie}</h2>
                    <p className='mt-2 h-17 text-gray-600 text-xs pt-2 sm:text-sm'>{description}</p>
                    <div className='flex item-center justify-between pt-1 h-8'>
                        <h1 className='text-gray-700 font-bold sm:text-xl'>{prix} CHF</h1>
                    </div>
                    <button className="hover:scale-105 px-3 py-2 mt-1 bg-gray-800 text-white text-xs font-bold uppercase rounded" onClick={handleAddCartProduct}>Ajouter au panier</button>
                    <Link to={`/carte/${id}`}>
                        < IoIosAdd className='flex float-right hover:scale-125 w-8 h-8 ' />
                    </Link>

                
                </div>
            </div>
        </div>



    )
}

export default CardFeature