import React, { useState } from 'react';
import logo from '../media/logo.webp';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaCartShopping } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { logoutRedux } from '../redux/userSlice';
import { toast } from 'react-hot-toast';
import { TiThMenu } from "react-icons/ti";





const Header = () => {
  const [showMenuUser, setShowMenuUser] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const userData = useSelector((state) => state.user);
  const cartItemNumber = useSelector((state) => state.product.cartItem);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleShowMenu = () => {
    if(showMenuUser){
      setShowMenu(prev => !prev);
      setShowMenuUser(prev=>!prev);
    }else{

      setShowMenu(prev => !prev);
    }
    
  };
  const handleShowMenuUser = () => {
    if(showMenu){
      setShowMenu(prev=>!prev);
      setShowMenuUser(prev => !prev);
    }else{

      setShowMenuUser(prev => !prev)
    }
  };

  const handleLogout = () => {
    dispatch(logoutRedux());
    toast.success("Vous vous êtes déconnecté en toute sécurité");
    navigate("/");
  }



  return (
    <header className='fixed shadow-md w-screen h-16 px-2 md:px-4 z-50 bg-white'>

      {/*desktop*/}
      <div className='flex items-center h-full justify-between'>
        <Link to={"/"}>
          <div className="h-14 mt-10">
            <img src={logo} className='h-full' alt="logo pikachu" loading="lazy" />
          </div>
          <h1 className="p-3 sm:p-4  text-lg sm:text-xl font-bold text-white bg-black" href='#'>
            Pokémark
          </h1>
        </Link>

        <div className='flex item-center gap-5 md:gap-7 relative'>
          <nav className='gap-4 md:gap-6 text-lg hidden sm:flex'>
            <Link to={"/"} className='font-semibold text-slate-600 hover:text-black'>Home</Link>
            <Link to={"cartes"} className='font-semibold text-slate-600 hover:text-black'>Cartes</Link>
            <Link to={"about"} className='font-semibold text-slate-600 hover:text-black'>À Propos</Link>
            <Link to={"contact"} className='font-semibold text-slate-600 hover:text-black'>Contact</Link>
          </nav>
          <Link to={"panier"} className='text-2xl sm:text-3xl text-slate-600 relative hover:text-black'>
            <FaCartShopping />
            <div className="absolute -top-2 -right-2 text-white bg-red-500 h-4 w-4 rounded-full text-xs m-0 p-0 text-center">
              {cartItemNumber.length? cartItemNumber.length : 0}
            </div>
          </Link>
          <div className='text-slate-600' onClick={handleShowMenuUser}>
            <div className='text-2xl sm:text-3xl cursor-pointer hover:text-black'>
              <FaUser />
            </div>
            {
              showMenuUser && (

                <div className="absolute right-0 bg-white py-2  shadow drop-shadow-md flex flex-col min-w[120px] text-center">
                  {
                    userData.firstName &&
                    <Link to={"compte"} className='whitespace-nowrap cursor-pointer px-2 py-1 text-lg text-slate-600 hover:text-black'>Modifier mon compte</Link>
                  }

                  {
                    userData.addProduct &&
                    <Link to={"addproduct"} className='whitespace-nowrap cursor-pointer px-2 py-1 text-lg text-slate-600 hover:text-black'>
                      Ajouter des produits
                    </Link>

                  }

                  {
                    userData.firstName ? <p className='cursor-pointer px-2 py-1 text-lg  text-slate-600 hover:text-black' onClick={handleLogout}>Se Déconnecter</p> : <Link to={"login"} className='whitespace-nowrap cursor-pointer px-2'>Se connecter</Link>
                  }
                </div>
              )
            }

          </div>
          <div className='text-slate-600 flex-col sm:hidden' onClick={handleShowMenu}>
            <div className='text-2xl sm:text-3xl cursor-pointer hover:text-black'>
              <TiThMenu />
            </div>
            {
             
              showMenu && (
                <div className="absolute right-0 bg-white py-2 px-3  shadow drop-shadow-md flex flex-col min-w[120px] text-center">
                
                <Link to={"/"} className='whitespace-nowrap cursor-pointer px-2 py-1 text-lg text-slate-600 hover:text-black'>Home</Link>
                <Link to={"cartes"} className='whitespace-nowrap cursor-pointer px-2 py-1 text-lg text-slate-600 hover:text-black'>Cartes</Link>
                <Link to={"about"} className='whitespace-nowrap cursor-pointer px-2 py-1 text-lg text-slate-600 hover:text-black'>À Propos</Link>
                <Link to={"contact"} className='whitespace-nowrap cursor-pointer px-2 py-1 text-lg text-slate-600 hover:text-black'>Contact</Link>
              
              </div>
              )
              
            }
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header